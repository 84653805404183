$(($) => {

	$(`a[href^="form"]`).each((index, element) => {
		const popupId = $(element).attr('href');
		const popupSrc = $(`#${popupId}`).parents('.bs-div--popup-form').attr('id');
		//console.log(popupSrc);
		const hashUrl = (popupSrc=="popup-subscribe-form") ? "#subscribe" : "#feedback";
		$(element).attr({
			'data-fancybox': true,
			'data-src': `#${popupSrc}`,
			'href': hashUrl,
		});
	});

	$('.elq-item-textarea').each((index, element) => {
		$(element).parents('.form-element-layout').parents('.row').addClass('textarea-row');
	});
	$('.single-checkbox-row').each((index, element) => {
		$(element).parents('.form-element-layout').parents('.row').addClass('privacy-approve-row');
	});

	$('.elq-item-input, .elq-item-textarea').each((index, element) => {
		const placeholderText = $(element).attr('placeholder');
		let labelName = $.trim($(element).parents('.form-element-layout').find('.elq-label').text());
		labelName = labelName.replace(/\s\s+/g, ' ');
		if(placeholderText === '' || placeholderText === undefined) {
			$(element).attr('placeholder', labelName)
		}
	});

	$('.elq-item-select').each((index, element) => {
		let labelName = $.trim($(element).parents('.form-element-layout').find('.elq-label').text());
		labelName = labelName.replace(/\s\s+/g, ' ');
		$(element).find('option:first').text(labelName);
});

	$('.bs-div--popup-form-feedback .list-order').first().parent('.field-control-wrapper').addClass('range-radio-group');
	$('.field-control-wrapper').each((index, element) => {
		if($(element).children('.list-order').length) {
			$(element).addClass('option-lists').parents('.form-element-layout').addClass('show-label').parents('.row').addClass('full-width-row');
		}
	});

});
