const _ = require('lodash');

(($) => {
	$(() => {
		const cardSection = $('.r-crdstk-l6--glb1');
		if (cardSection.length !== 0) {
			cardSection.each((index, element) => {
				const column = $(element).find('.bs-column');

				column.each((index, element) => {
					const elId =
						(index / 3).toFixed(2).split('.')[1].substring(0, 1) /
						3;
					const oddEven =
						(index / 2).toFixed(2).split('.')[1].substring(0, 1) ==
						0
							? 'even'
							: 'odd';
					$(element).addClass('box-no-' + elId);
					$(element).addClass(oddEven);
				});

				column.on('click', (e) => {
					column.removeClass('active');
					$(e.currentTarget).addClass('active');
					$(element).removeClass('has-active-popup');
					const activeClass = _.debounce(() => {
						$(element).addClass('has-active-popup');
					}, 300);
					activeClass();
					const columnPerRow = Math.floor(
						parseInt(
							$(element).innerWidth() / column.eq(0).innerWidth()
						)
					);
					const infoBox = $(e.currentTarget)
						.find('.bs-card + .bs-div')
						.clone(true);
					const closeEl = document.createElement('div');
					$(closeEl).addClass('close');
					infoBox.append(closeEl);
					const cloneEl = $(element).find('.info-box');
					if (cloneEl.length !== 0) {
						cloneEl.remove();
					}

					const elPos =
						$(e.currentTarget).offset().top +
						$(e.currentTarget).innerHeight();
					const headElHeight = $('#header').innerHeight() + 25;
					$('html, body').animate(
						{ scrollTop: elPos - headElHeight },
						500
					);

					const selectedIndex = parseInt($(e.currentTarget).index());
					const findRow = Math.floor(
						selectedIndex / columnPerRow + 1
					);
					const appendAfter =
						(columnPerRow - selectedIndex + selectedIndex) *
						findRow;
					infoBox.addClass('info-box');

					const elNo =
						column.length < appendAfter
							? column.length - 1
							: appendAfter - 1;
					infoBox.insertAfter(column.eq(elNo));

					$(element)
						.find('.info-box .close')
						.on('click', () => {
							column.removeClass('active');
							$(element).removeClass('has-active-popup');
							const removeInfoBox = _.debounce(() => {
								$(element).find('.info-box').remove();
							}, 300);
							removeInfoBox();
						});
				});

				const resizeFunc = () => {
					const columnPerRow = Math.floor(
						parseInt(
							$(element).innerWidth() / column.eq(0).innerWidth()
						)
					);
					const activeBlock = $(element).find('.bs-column.active');
					if (activeBlock.length !== 0) {
						const selectedIndex = parseInt(activeBlock.index());
						const findRow = Math.floor(
							selectedIndex / columnPerRow + 1
						);
						const appendAfter =
							(columnPerRow - selectedIndex + selectedIndex) *
							findRow;
						const elNo =
							column.length < appendAfter
								? column.length - 1
								: appendAfter - 1;
						const infoBox = $(element).find('.info-box');
						infoBox.insertAfter(column.eq(elNo));
					}
				};
				$(window).on(
					'orientationchange resize',
					_.debounce(resizeFunc, 300)
				);
			});
		}
	});
})(jQuery);
