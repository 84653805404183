/* eslint-env jquery */
(($) => {
	const heroBanner = $('.r-hrobnr-l5--glb1'),
		obCallback = (payload) => {
			const ratio = payload[0].intersectionRatio;
			if (ratio > 0.25) {
				$(payload[0].target)
					.find('strong')
					.parent()
					.addClass('text-anim');
			} else {
				$(payload[0].target)
					.find('strong')
					.parent()
					.removeClass('text-anim');
			}
		};
	$(() => {
		heroBanner.each((ind, ele) => {
			const animEls = $(ele).find('strong'),
				ob = new window.IntersectionObserver(obCallback, {
					root: null,
					threshold: [0.1, 0.3],
				});
			if (animEls.length > 0) {
				animEls.wrap('<span class="text-wrap"></span>');
				ob.observe(heroBanner[ind]);
			}
		});
	});
})(jQuery);
