(function ($) {
	//active 'All' checkbox when reset button click
	$('.reset-filters').on('click', function () {
		$('.facet-checkbox-select-all').addClass('checked');
	});

	//on page load hide the reset button
	if ($('.facetwp-selection-value').length <= 0) {
		$('.reset-selection').hide();
	}

	//'All' check box on click action
	$('.facet-checkbox-select-all').on('click', (event) => {
		$(event.target).addClass('checked');
	});

	const mobilePaginationClassAdding = () => {
    if ($('.facetwp-facet-pagination').length > 0) {
      if ($(window).width() < 576) {
        const paginationElements = $('.facetwp-facet-pagination .facetwp-page');
        const dotsContent = '<a class="facetwp-page dots">…</a>';
        const activeIndex = $('.facetwp-facet-pagination').find('.active').index();
        const lastIndex = paginationElements.length - 1;
        paginationElements.each((i, e) => {
          const element = $(e);
          if (!(i === 0 || i === 1 || i === lastIndex - 1 || i === lastIndex || i === (activeIndex - 1) || i === activeIndex || i === (activeIndex + 1)))
            element.addClass('hide-mobile');
          if (i > 1 && i < (activeIndex - 1))
            element.addClass('left-items');
          if (i > (activeIndex + 1) && i < (lastIndex - 1))
            element.addClass('right-items');
        });
        const leftHiddenContent = $('.facetwp-facet-pagination .facetwp-page.hide-mobile.left-items');
        const rightHiddenContent = $('.facetwp-facet-pagination .facetwp-page.hide-mobile.right-items');
        if (typeof leftHiddenContent != 'undefined' && leftHiddenContent.length > 0) {
          leftHiddenContent.first().before(dotsContent);
          leftHiddenContent.remove();
        }
        if (typeof rightHiddenContent != 'undefined' && rightHiddenContent.length > 0) {
          rightHiddenContent.last().after(dotsContent);
          rightHiddenContent.remove();
        }
      } else {
				$('.facetwp-facet-pagination .facetwp-page').removeClass('hide-mobile')
			}
    }
  };

	$(document).on('facetwp-loaded', () => {
		let queryString = FWP.buildQueryString();
		if ('' === queryString) {
			// no facets are selected
			$('.reset-selection').hide();
		} else {
			$('.reset-selection').show();
		}

		//remove facetwp-all-* class when any facet has selected item
		const facets = FWP.facets;
		$.each(facets, (key, facet) => {
			if (facets[key].length) {
				$(`.facetwp-all-${key}`).removeClass('checked');
			} else {
				$(`.facetwp-all-${key}`).addClass('checked');
			}
		});

		mobilePaginationClassAdding();

	});

	$(window).on("load resize", (e) => {
    mobilePaginationClassAdding();
  });

})(jQuery);
