/* eslint-env jquery */
/* global debounce */

$(($) => {

	const flipBoxHeight = () => {
		$('.bs-div--flip-box').each((index, element)=> {
			const scrollH = $(element).find('.bs-div').first().children('.bs-div__inner')[0].scrollHeight;
			const paddingTop = parseInt($(element).find('.bs-div').first().css('padding-top'));
			const paddingBottom = parseInt($(element).find('.bs-div').first().css('padding-bottom'));
			const marginTop = parseInt($(element).parent().css('margin-top'));
			if($(window).width() > 767) {
				$(element).css('height', `${scrollH + paddingTop + paddingBottom - 2}px`);
			} else {
				$(element).css('height', 'auto')
			}
		});
	}

	const flipBoxArray = [];
	const flipBoxWrap = () => {
		$('.bs-div--flip-box').parent().each((index, element)=> {
			flipBoxArray.push($(element));
		});
	}
	
	const appendFlipBox = (flipBoxArray, element) => {
		if(element.length && !$('.left-side').length && !$('.right-side').length) {
			element.append('<div class="bs-column col-md-6 left-side flip-wrapper"></div><div class="bs-column col-md-6 right-side flip-wrapper"></div>');
			$.each(flipBoxArray, ( index, value ) => {
				if (index % 2 == 0) {
					value.clone().appendTo(element.find('.left-side'));
				} else {
					value.clone().appendTo(element.find('.right-side'));
				}
			});
		}
	}

	flipBoxWrap();
	appendFlipBox(flipBoxArray, $('.bs-row--masonry-layout'));
	flipBoxHeight();

	const items = $('.flip-wrapper');
	const observer = new IntersectionObserver(
		(entries, observer) => {
			entries.forEach((entry) => {
				const item = $(entry.target);
				if (entry.isIntersecting) {
					item.addClass('active');
				}
			});
		},
		{ threshold: 1 }
	);

	$(window).on('load scroll resize', () => {
		items.each((i, item) => {
			observer.observe(item);
			$('.left-side').parallaxMove({ offset: 50, headerFixed: true });
			$('.right-side').parallaxMove({ offset: 100, headerFixed: true });
		});
	});

	const debouncedHandlers = () => {
		appendFlipBox(flipBoxArray, $('.bs-row--masonry-layout'));
		flipBoxHeight();
	};

	// Calls on window resize
	$(window).on('resize', debounce(debouncedHandlers, 100));

});
