$(($) => {
	const header = $('header');

	//Add Class on Scroll
	$(window).scroll(() => {
		if (window.pageYOffset >= 1) {
			header.addClass('scrolled');
		} else {
			header.removeClass('scrolled');
		}
	});
	$(
		'#mega-menu-main-menu > li.mega-menu-item > a.mega-menu-link > span.mega-indicator'
	).on('click', function (e) {
		const $link = $(this);
		const $parent = $link.parents('.mega-menu-item');
		$('a.mega-menu-link').attr('aria-expanded', false);
		if (!$parent.hasClass('mega-toggle-on')) {
			$parent.removeClass('mega-toggle-on');
			$link.parent().attr('aria-expanded', false);
		} else {
			$('.mega-toggle-on').removeClass('mega-toggle-on');
			$link.parent().attr('aria-expanded', true);
			$parent.addClass('mega-toggle-on');
		}
	});

// 	//clone to mobile menu header search and buttons
	const mainMenu = $('header .header__navigation.main-menu'),
		navBar = $('ul.mega-menu'),
		contents = $('.header-button-wrapper').clone();
		$(navBar).append(contents);
		$(navBar).find('.header-button-wrapper').wrap('<li></li>')
	
		//desktop search pop up
	$(function () {
		const searchButton = $('.search-icon');
		searchButton.on('click', function () {
			$('form').toggleClass('show-search-bar');
			$('.search-wrapper').toggle();
			searchButton.toggleClass('open-search-popup');
      $('.searchform #s').focus();
		});
	});

  //header box shadow for some pages
	if ($('section').hasClass('bs-section--helper-header-box-shadow')) {
		$(header).addClass('box-shadow');
	}   
});

