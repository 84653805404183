/* global debounce */
// eslint-disable-next-line no-undef
$(($) => {
	$('.bs-advance-accordion .accordion__block__btn').on(
		'click',
		debounce((e) => {
			const scrollValue =
				$(e.currentTarget).closest('.card').offset().top -
				$('header').height();
			//$('html, body').animate({ scrollTop: scrollValue }, 50);
		}, 500)
	);
});

$(document).ready(function () {
	$(window).on('resize', function (e) {
		checkScreenSize();
	});

	checkScreenSize();

	function checkScreenSize() {
		var newWindowWidth = $(window).width();
		if (newWindowWidth < 576) {
			$('.bs-slider-accordion .accordion__block__btn').on(
				'click',
				debounce((e) => {
					const scrollValue =
						$(e.currentTarget).closest('.card').offset().top - 60;
					//$('html, body').animate({ scrollTop: scrollValue }, 50);
				}, 500)
			);
		}
	}
});
