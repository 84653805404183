$(($) => {
	const items = $('.bs-div--ribbon-animation ul');
	const animatedClassName = 'bs-div--ribbon-animation__list';
	const observer = new IntersectionObserver(
		(entries, observer) => {
			entries.forEach((entry) => {
				const item = $(entry.target);
				if (entry.isIntersecting) {
					item.addClass(animatedClassName);
					setTimeout(() => {
						item.children().css('text-overflow', 'ellipsis');
					}, 1300);
				} else {
					item.children().css('text-overflow', 'clip');
					item.removeClass(animatedClassName);
				}
			});
		},
		{ rootMargin: '0px', threshold: 1 }
	);
	items.each((i, item) => {
		observer.observe(item);
	});
});
