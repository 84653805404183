$(($) => {
	$('.bs-div--facet-filter').on('click', () => {
		$('.facetwp-facet').toggle();
	});
	$(document).on('click', '.facetwp-radio', () => {
		$('.facetwp-facet').toggle();
		$('.bs-div--facet-filter, .facetwp-template').addClass('loading');
	});

	const activeCategory = () => {
		let activeText = '';
		if ($('.checked .facetwp-display-value').length) {
			activeText = $('.checked').find('.facetwp-display-value').html();
		} else {
			activeText = $('.facetwp-facet .facetwp-radio:first').html();
		}
		$('.bs-div--facet-filter .bs-div__inner').html(activeText);
	};

	activeCategory();

	$(document).on('facetwp-loaded', () => {
		activeCategory();
		$('.bs-div--facet-filter, .facetwp-template').removeClass('loading');
	});
});
