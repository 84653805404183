/* eslint-disable no-lonely-if */
// eslint-disable-next-line no-undef
//updated Nov 18
$(($) => {
	const waitingTime = 1000;
	$('.elq-item-textarea').each((i, ele) => {
		$(ele).val(ele.value.trim());
	});

	$('.elq-item-select').each((i, ele) => {
		const setStatus = (item) => {
			if (item.val().trim()) item.removeClass('elq-item-select--empty');
			else item.addClass('elq-item-select--empty');
		};

		ele = $(ele);
		setStatus(ele);

		ele.on('change', (e) => setStatus($(e.target)));
	});

	$('form.elq-form').on('submit', (e) => {
		const form = $(e.target);
		setTimeout(() => {
			let errVal = 0;
			form.find('span').each(function () {
				if ($(this).hasClass('LV_invalid')) errVal = 1;
			});
			if (errVal !== 0) {
				const submitButton = form.find('input[type=submit]');
				const spinner = form.find('.loader');
				submitButton.attr('disabled', false);
				submitButton.css('cursor', 'pointer');
				spinner.remove();
			}
		}, waitingTime);
	});

	//get cookie and hide forms
	const formCookie = getCookie('formCookie');
	if (formCookie !== 'form482') {
		$('.bs-div--interested-learning-more, .bs-section--stay-up-date').addClass(
			'show-subscribe'
		);
	}

	//gated form redirect
	$('#'+$('#eqFormId').val()).on('submit', (e) => {
		// eslint-disable-next-line no-unused-expressions
		e.preventDefault();
		//get the action-url of the form
		const actionURL = e.currentTarget.action;
		const formData = $('#'+$('#eqFormId').val()).serialize();
		const formType = $('#elqFormType').val();
		//do your own request an handle the results
		$.ajax({
			url: actionURL,
			type: 'post',
			data: formData,
			// error:function(a,b,c) {
			// 	console.log(a);
			// 	console.log(b);
			// 	console.log(c);
			// },
			success: (data) => {
				//console.log(data);
				let responseData = data;
				if(responseData.includes('Validations')){
					console.log('Error on submit!');
				}else{
					if(formType == "gatedForm"){
						setCookie('wp_e25_pg_auth', $('#eqFormId').val(), 1);
						window.open($('#pageLink').val(), '_self');
					}
				}
			},
		});
	});
});

const getCookie = (name) => {
	const nameEQ = name + '=';
	const ca = document.cookie.split(';');
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === ' ') c = c.substring(1, c.length);
		if (c.indexOf(nameEQ) === 0)
			return c.substring(nameEQ.length, c.length);
	}
	return null;
};

const setCookie = (name, value, days) => {
	let expires = '';
	if (days) {
		const date = new Date();
		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
		expires = '; expires=' + date.toUTCString();
	}
	document.cookie = name + '=' + (value || '') + expires + '; path=/';
};