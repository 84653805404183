/*eslint-env jquery */
/* eslint-disable no-undef, import/no-extraneous-dependencies */
/* eslint-disable no-undef, eqeqeq */
(($) => {
	const { debounce } = require('lodash');

	const $slider = $('.bs-slider--r-tesslr-l7-glb1'),
		$slides = $slider.find('.slick-slide-wrapper');
	let maxHeightArray = [];
	const windowWidth = $(window).innerWidth();

	//Get maximum height
	const getSlideElementHeight = () => {
		$slides.each((i, e) => {
			const height = $(e).innerHeight();
			maxHeightArray.push(height);
		});
	};

	const matchHeight = (heightGap) => {
		const maxValueOfY = Math.max(...maxHeightArray);
		maxHeightArray = [];
		const fixHeight = `${maxValueOfY - heightGap}px`;
		$slider.find('.slick-track').css('min-height', maxValueOfY + 100);
		$slider.find('.slick-slide').css('height', fixHeight);
		return maxValueOfY;
	};

	//Slider height init
	const slideHeightInit = () => {
		getSlideElementHeight();
		const slideHeight = matchHeight(windowWidth < 1200 ? 40 : 60);
		$slider.find('.slick-current').css('height', slideHeight);
	};

	//Adding styles
	const addClassStyles = () => {
		$slider
			.find('.slick-current')
			.next()
			.css('opacity', 1)
			.addClass('translate-next');
		$slider.find('.slick-current').prev().addClass('translate-prev');
	};

	//Trigger on resize
	$(window).on('resize', () => {
		debounce(() => {
			slideHeightInit();
		}, 500)();
	});

	$(window).on('load', () => {
		slideHeightInit();
		addClassStyles();

		//Slider after change
		$slider.on('afterChange', () => {
			setTimeout(() => {
				// For remove prev/next Classes
				$slider
					.find('.slick-slide')
					.removeClass('translate-next translate-prev');
				addClassStyles();
			}, 300);
		});

		//Slider before Change
		$slider.on('beforeChange', (event, slick, current, next) => {
			if (current == next) return;

			getSlideElementHeight();
			const slideHeight = matchHeight(windowWidth < 1200 ? 40 : 60);
			const $currentSlide = $slider.find('.slick-current');

			$currentSlide.addClass('prev-current');

			setTimeout(() => {
				// For remove  prev current class
				$currentSlide.removeClass('prev-current');
			}, 800);

			const $sibling =
				current < next ? $currentSlide.next() : $currentSlide.prev();

			$sibling.css('height', slideHeight);
		});
	});
})(jQuery);
