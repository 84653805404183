$(($) => {
	const interestedContainerClass = 'bs-div--interested-learning-more';
	const feedbackContainerClass = 'bs-pro-button--feedback';
	const interestedBox = $(`.${interestedContainerClass}`);
	const feedbackButton = $(`.${feedbackContainerClass}`);
	if (!interestedBox.get(0) && !feedbackButton.get(0)) return;

	interestedBox.each((index, element) => {
		if (!$(element).find(`.${interestedContainerClass}__close-button`).length) {
			$('<a href="#" class="close-button"></a>').appendTo($(element));
		}
	});

	interestedBox
		.find(`.close-button`)
		.on('click', (e) => {
			e.preventDefault();
			interestedBox.addClass(`${interestedContainerClass}--hidden`);
		});

	const footer = $('footer').get(0);
	if (footer) {
		const observer = new IntersectionObserver(
			(entries) => {
				$(`.${interestedContainerClass}`).toggleClass(
					`${interestedContainerClass}--with-footer`,
					entries[0].isIntersecting
				);
				$(`.${feedbackContainerClass}`).toggleClass(
					`${feedbackContainerClass}--with-footer`,
					entries[0].isIntersecting
				);
			},
			{ threshold: 0.1 }
		);
		observer.observe(footer);
	}

});
