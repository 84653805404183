/* eslint-env jquery */
/* global debounce */
require('slick-carousel-latest');

(function ($, w) {
	const initProgressBar = () => {
		$('.bs-slider--add-progress-bar').each((index, element) => {
			const $sliderParent = $(element);
			const $slider = $sliderParent.find('[data-slick]');
			const uniqSliderId = $slider.attr('id');
			let animationWidth = 0;

			if (!$sliderParent.find('.progress-bar__container').length) {
				$sliderParent.append(`
				<div class="progress-bar__container">
					<div class="progress-bar__wrapper">
						<span class="progress-bar__inner"></span>
					</div>
				</div>`);
			}

			$(`#${uniqSliderId}`).on(
				'init reInit',
				function (event, slick, currentSlide, nextSlide) {
					const animationBarWidth =
						(1 /
							(slick.slideCount -
								slick.slickGetOption('slidesToShow') +
								1)) *
						100;
					if (window.matchMedia('(max-width: 767px)').matches) {
						animationWidth = `calc(${animationBarWidth}% - 10px)`;
					} else {
						animationWidth = `calc(${animationBarWidth}% - 15px)`;
					}
					const $animateBarContainer = $sliderParent
						.find('.progress-bar__inner')
						.css({
							width: animationWidth,
							'transition-duration':
								slick.slickGetOption('speed') + 'ms',
						});
					if (
						animationBarWidth < 0 ||
						animationBarWidth === 100 ||
						animationBarWidth === Infinity
					) {
						$animateBarContainer
							.parents('.progress-bar__container')
							.addClass('hide');
					} else {
						$animateBarContainer
							.parents('.progress-bar__container')
							.removeClass('hide');
					}
				}
			);

			$('#' + uniqSliderId).on(
				'beforeChange',
				function (event, slick, currentSlide, nextSlide) {
					const animationBarWidth =
						((nextSlide + 1) /
							(slick.slideCount -
								slick.slickGetOption('slidesToShow') +
								1)) *
						100;
					if (window.matchMedia('(max-width: 767px)').matches) {
						animationWidth = `calc(${animationBarWidth}% - 10px)`;
					} else {
						animationWidth = `calc(${animationBarWidth}% - 15px)`;
					}
					$sliderParent.find('.progress-bar__inner').css({
						width: animationWidth,
						'transition-duration':
							slick.slickGetOption('speed') + 'ms',
					});
					$('.slick-current').prev().removeClass('prevdiv');
				}
			);

			$(`#${uniqSliderId}`).on(
				'afterChange',
				function (event, slick, currentSlide, nextSlide) {
					const animationBarWidth =
						(1 /
							(slick.slideCount -
								slick.slickGetOption('slidesToShow') +
								1)) *
						100;
					if (
						animationBarWidth < 0 ||
						animationBarWidth === 100 ||
						animationBarWidth === Infinity
					) {
						$sliderParent
							.find('.progress-bar__container')
							.addClass('hide');
					} else {
						$sliderParent
							.find('.progress-bar__container')
							.removeClass('hide');
					}
					$('.slick-current').prev().addClass('prevdiv');
				}
			);
		});
	};

	initProgressBar();

	const debouncedHandlers = () => {
		initProgressBar();
	};

	// Calls on window resize
	$(w).on('resize', debounce(debouncedHandlers, 100));
})(jQuery, window);
