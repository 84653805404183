$(($) => {

  const animateText = (element) => {
    element.find('span').each((index, ele)=> {
      const $this = $(ele);
      let animatedSpan = '';
      if($this.data('title') === '' || $this.data('title') === undefined) {
        $this.attr('data-title', $this.html());
      }
      animatedSpan = $this.data('title');
      $this.html('').addClass('visible');
      let i = 0;
      const speed = 100;
      setInterval( () => {
        if (i < animatedSpan.length) {
          $this.text($this.text() + animatedSpan.charAt(i));
          i++;
        }
      }, speed);
    });
  }

	const items = $('.animate-text');
  let once = false;
	const observer = new IntersectionObserver(
		(entries, observer) => {
			entries.forEach((entry) => {
				const item = $(entry.target);
				if (entry.isIntersecting && once === false) {
					animateText($('.animate-text'));
          once = true;
				}
			});
		},
		{ threshold: 1 }
	);
	items.each((i, item) => {
		observer.observe(item);
	});
});
