/* eslint-env jquery */
const Isotope = require('isotope-layout');

$(($) => {
	const wrapItems = () => {
		const postWrapper = $('.bs-post__details');
		postWrapper.each((index, element) => {
			$(element)
				.find(
					'.bs-post__resource-type , .bs-post__date, .bs-post__category, .bs-post-event_date, .bs-post__event-type'
				)
				.wrapAll('<div class="bs-post__date-category-wrapper" />');
		});
	};

	const appendTitles = () => {
		if ($('.bs-column--events-titles').length) {
			const parentRow = $('.bs-column--events-titles').parents('.bs-row');
			parentRow.each((index, element) => {
				const titleColumn = $(element).find(
					'.bs-column--events-titles'
				);
				const postParent = $(element).find('.bs-posts__normal-row');
				if (
					postParent.length &&
					!postParent.children('.bs-column--events-titles').length
				) {
					postParent.prepend(titleColumn.clone());
				}
			});
		}
	};

	$('.bs-posts__featured .bs-post').each((index, element) => {
		if (!$(element).find('.bs-post__featured-image').length) {
			$(element).addClass('no-featured-image');
		}
	});

	const searchResult = () => {
		if (
			window.location.href.indexOf('_search') > -1 &&
			$('.bs-posts__list').length
		) {
			$(
				'.bs-posts__list, .bs-post-block---default, .facetwp-template-static'
			).addClass('search-results');
		} else {
			$(
				'.bs-posts__list, .bs-post-block---default, .facetwp-template-static'
			).removeClass('search-results');
		}
	};

	const setGridLayout = () => {
		if ($('#masonry-layout').length) {
			new Isotope('#masonry-layout', {
				itemSelector: '.bs-posts__column',
				masonry: {
					gutter: 70,
				},
			});
			$('.facetwp-template').addClass('loaded');
		}
	};

	wrapItems();
	appendTitles();
	searchResult();
	setGridLayout();

	$('body').addClass('loaded');

	$(document).on('click', '.facetwp-radio, .facetwp-page', () => {
		$('.facetwp-facet-pagination, .facetwp-template').addClass(
			'is-loading'
		);
	});

	let adminBarHeight = 0;
	let subscribeBar = 0;
	$(document).on('facetwp-loaded', () => {
		if ($('#wpadminbar').length) {
			adminBarHeight = parseInt($('#wpadminbar').outerHeight());
		}
		if($('.subscribe-bar-fixed').is(":visible").length){
			subscribeBar = parseInt($('.subscribe-bar-fixed').outerHeight());
		}
		if($('.bs-section--stay-up-date').length){
			subscribeBar = parseInt($('.bs-section--stay-up-date').outerHeight());
		}
		// eslint-disable-next-line no-undef
		if (FWP.loaded) {
			// $('html, body').animate(
			// 	{
			// 		scrollTop:
			// 		$('.facetwp-template').parents('section').offset().top -
			// 		$('header').outerHeight() -
			// 		adminBarHeight - subscribeBar,
			// 	},
			// 	1000
			// );
		}

		wrapItems();
		appendTitles();
		searchResult();
		setGridLayout();

		const $pagination = $('.facetwp-facet-pagination');
		if ($('.facetwp-page').length && $pagination.length) {
			$pagination.show();
		} else {
			$pagination.hide();
		}

		$('.facetwp-template').removeClass('is-loading');
	});
});
