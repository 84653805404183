/* eslint-env jquery */
(($) => {
	const sections = $('.r-clxcnt-l5--glb1'),
		wrapperDiv = '>.container > .bs-row > .bs-column > .bs-div';
	$(window).on('load scroll resize', () => {
		sections.each((i, section) => {
			const parallaxEl1 = $(section).find(
					`${wrapperDiv} > .bs-background`
				),
				parallaxEl2 = $(section).find('.bs-div--r-clxcnt-l5-glb1'),
				parallaxEl3 = $(section).find(
					`${wrapperDiv} > .bs-div__inner > .bs-div > .bs-div__image-container`
				);
			parallaxEl1.parallaxMove({ offset: 150 });
			parallaxEl2.parallaxMove({ offset: 300 });
			parallaxEl3.parallaxMove({ offset: 200 });
		});
	});
})(jQuery);
