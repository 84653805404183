/* global FancyappsUi */
/* eslint-env jquery */
import { Fancybox } from '@fancyapps/ui/src/Fancybox/Fancybox.js';
window.FancyappsUi = Fancybox;

FancyappsUi.bind('[data-fancybox]', {
	dragToClose: false,
	preload: 0,
	autoFocus: false,
	Html: {
		html5video: {
			tpl:
				'<video class="fancybox-video" controls controlsList="nodownload">' +
				'<source src="{{src}}" type="{{format}}" />' +
				'Sorry, your browser doesn\'t support embedded videos, <a href="{{src}}">download</a> and watch with your favorite video player!' +
				'</video>',
		},
	},
	Carousel: {
		Navigation: false,
	},
	on: {
		load: (fancybox, slide) => {
			// Adding fancybox custom class name into the wrapper
			$(slide.$el)
				.closest('.fancybox__container')
				.addClass(slide.mainClass);
		},
	},
});

// add class to popup
$('.bs-section--sass-talk-inner a[data-fancybox="true"]').each(
	(index, element) => {
		$(element).attr('data-auto-focus', 'false');
		$(element).on('click', (e) => {
			if ($('.fancybox-content').length) {
				$('.fancybox-container').addClass('night-dragon-popup');
			}
		});
	}
);
