/* eslint-env jquery */
(($) => {
	$.fn.parallaxMove = function (options) {
		const settings = $.extend(
			{
				offset: 0,
				mobile: false,
				dir: 'top',
				headerFixed: false,
			},
			options
		);
		const currEl = this,
			wH = $(window).height(),
			wW = $(window).width(),
			hH = $('header').height(),
			parent = currEl.parents('section'),
			boundingBox = parent[0].getBoundingClientRect(),
			parentTop = boundingBox.top,
			parentBottom = boundingBox.bottom,
			speed = settings.headerFixed
				? settings.offset / (wH - hH)
				: settings.offset / wH,
			inViewHeight = wH - parentTop,
			scrollPortion =
				settings.dir === 'top'
					? settings.offset - inViewHeight * speed
					: (settings.offset - inViewHeight * speed) * -1,
			disableParallax = wW < 768 && !settings.mobile ? true : false;

		if (parentTop <= wH && parentBottom >= 0 && !disableParallax) {
			currEl.css({
				transform: 'translateY(' + scrollPortion + 'px)',
			});
		} else if (disableParallax) {
			currEl[0].style.removeProperty('transform', '-webkit-transform');
		}
		currEl.addClass('parallax-item');
	};
})(jQuery);
