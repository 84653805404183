$(($) => {
	$(`.bs-pro-button--popup>a`).each((index, element) => {
		element = $(element);
		const popupId = element.attr('href');
		console.log(popupId);
		element.attr({
			'data-fancybox': popupId,
			'data-src': `#${popupId}`,
			href: '#',
		});
	});
});
