$(($) => {
	const hasSection = $('.bs-section--test').length;
	if (hasSection !== 0) {
		var n = 0;
		$(window).on('scroll', function () {
			n = n + 2;
			console.log(n);
			$('.bs-counter').css('transform', 'translateY(' + n + 'px)');
		});
	} else {
		const sections = $(
			'.r-clxcnt-l7--glb1,.bs-section--home-not-all-capital'
		);
		const observer = new IntersectionObserver(
			(entries, observer) => {
				entries.forEach((entry) => {
					const section = $(entry.target);
					if (entry.isIntersecting) {
						section
							.prev()
							.addClass('home-not-all-capital--inverse-prev');
						section.addClass('home-not-all-capital--inverse');
						section
							.next()
							.addClass('home-not-all-capital--inverse-next');
					} else {
						section
							.prev()
							.removeClass('home-not-all-capital--inverse-prev');
						section.removeClass('home-not-all-capital--inverse');
						section
							.next()
							.removeClass('home-not-all-capital--inverse-next');
					}
				});
			},
			{ threshold: 0.2, rootMargin: '-90px 0px 0% 0px' }
		);
		$(window).on('load scroll resize', () => {
			sections.each((i, section) => {
				section = $(section);
				if (section.hasClass('bs-section--about-counter')) {
					section.addClass('home-not-all-capital--inverse');
				} else {
					observer.observe(section.get(0));
				}
				if (section.hasClass('r-clxcnt-l7--glb1')) {
					const wrapperDiv =
						'.row:last-child .bs-column>.bs-div>.bs-div__inner';
					const counters = section.find(
						`${wrapperDiv} > .bs-counter`
					);
					const contentOne = section.find(
						`${wrapperDiv} > .bs-div--content-one`
					);
					const contentTwo = section.find(
						`${wrapperDiv} > .bs-div--content-two`
					);
					$(counters.get(0)).parallaxMove({
						offset: 100,
						headerFixed: true,
					});
					contentOne.parallaxMove({ offset: 100, headerFixed: true });
					$(counters.get(1)).parallaxMove({
						offset: 200,
						headerFixed: true,
					});
					$(counters.get(2)).parallaxMove({
						offset: 200,
						headerFixed: true,
					});
					$(counters.get(3)).parallaxMove({
						offset: 200,
						headerFixed: true,
					});
					if (contentTwo.length)
						contentTwo.parallaxMove({
							offset: 200,
							headerFixed: true,
						});
				} else if (
					section.hasClass('bs-section--home-not-all-capital')
				) {
					const contentOne = section.find(`.bs-div--content-one`);
					const contentTwo = section.find(`.bs-div--content-two`);
					const counters = section.find(`.bs-counter`);
					if (contentOne.length)
						contentOne.parallaxMove({
							offset: 500,
							headerFixed: true,
						});
					if (contentTwo.length)
						contentTwo.parallaxMove({
							offset: 100,
							headerFixed: true,
						});
					counters.each((i, counter) => {
						const offset =
							i % 3 === 0 ? 500 : i % 3 === 1 ? 300 : 100;
						$(counter).parallaxMove({
							offset: offset,
							headerFixed: true,
						});
					});
				}
			});
		});
	}
});
