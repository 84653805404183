/* eslint-env jquery */
/* global Waypoint, debounce */
require('slick-carousel-latest');

(function ($, w) {
	const initSlick = () => {
		if ($('[data-slick]').length) {
			$('[data-slick]')
				.not('.bs-slider-accordion-slick')
				.not('.slick-initialized')
				.slick();
		}
	};

	const autoPlaySpeedFunction = () => {
		$(
			'.bs-tab-slider--progress-bar :not(.bs-slider-content)[data-slick]'
		).each((index, ele) => {
			if ($(ele).hasClass('slick-initialized')) {
				const slick = $(ele).slick('getSlick');

				if (slick.options.autoplay === true) {
					$(ele).slick('slickGoTo', 0);
					//append wrapper class and animator span to each slick slide element
					$.each($(ele).find('.slick-slide'), (i, element) => {
						const animatorElement = $(element).find(
							'.slick-slide-wrapper .slick-slide-animator'
						);
						animatorElement.unwrap();
						animatorElement.remove();

						$(element)
							.find('.slick-slide-wrapper')
							.children()
							.wrapAll(
								'<div class="slick-slide-title-wrapper"></div>'
							);
						const $content = $(element).find(
							'.slick-slide-title-wrapper'
						);
						$content.append(
							'<span class="slick-slide-animator"></span>'
						);
					});
					//set animator animation-duration
					const playSpeed = slick.options.autoplaySpeed;
					$(ele)
						.find('.slick-slide-animator')
						.attr('data-speed', `${playSpeed}ms`)
						.css('animation-duration', `${playSpeed}ms`);
				} else {
					//remove wrapper and animator span from element when auto play off
					$.each($(ele).find('.slick-slide'), (i, element) => {
						const animatorElement = $(element).find(
							'.slick-slide-wrapper .slick-slide-animator'
						);
						animatorElement.unwrap();
						animatorElement.remove();
					});
				}
			}
		});
	};

	// To refresh the initiated slick sliders
	const refreshSlick = () => {
		$('.slick-initialized').each(function (key) {
			if (
				$('.slick-initialized')[key] &&
				$('.slick-initialized')[key].slick
			) {
				$('.slick-initialized')[key].slick.refresh();
			}
		});
	};

	const initWaypoint = () => {
		$.each(
			$(
				'.bs-tab-slider--progress-bar .bs-slider-tabs.slick-initialized'
			).closest('.bs-section'),
			(index, item) => {
				new Waypoint({
					element: item,
					handler() {
						const currentSlide = $(this.element)
							.find('.bs-slider-tabs.slick-initialized')
							.find('.slick-current')
							.attr('data-slick-index');
						// Resetting only when current slide is 2 or greater
						if (currentSlide > 0) {
							$(this.element)
								.find('.bs-slider-tabs.slick-initialized')
								.slick('slickGoTo', 0);
						}
					},
					offset() {
						// Current window height + 50 pixels
						return this.context.innerHeight() + 50;
					},
				});
			}
		);
	};

	const debouncedHandlers = () => {
		initSlick();
		autoPlaySpeedFunction();
		refreshSlick();
	};
	// Calls when the window is fully loaded
	$(w).on('load', () => {
		initSlick();
		autoPlaySpeedFunction();
		initWaypoint();
	});

	// Calls on window resize
	let windowWidth = window.innerWidth;
	//$(w).on('resize', debounce(debouncedHandlers, 500));
	$(w).on( 'resize', () => {
    if( windowWidth !== window.innerWidth ) {
			debounce(debouncedHandlers, 500);
    }
    windowWidth = window.innerWidth;
	});

})(jQuery, window);
