/* eslint-env jquery */
/* eslint-disable no-nested-ternary */
(($) => {
	const animatingEls = $('.bs-div--r-scltxt-l1-glb1 > .bs-div__inner > *'),
		speedFunc = (parent, child) => {
			const wH = $(window).height(),
				parentWidth = parseFloat(parent.width().toFixed(1)),
				parentLeft = parseFloat(parent.offset().left.toFixed(1)),
				wW =
					parentWidth < $(window).width()
						? parentWidth
						: $(window).width(),
				childWidth = parseFloat(child.width().toFixed(1)),
				childLeft =
					parentWidth < wW
						? parentLeft +
						  parseFloat(child.offset().left.toFixed(1))
						: parseFloat(child.offset().left.toFixed(1)),
				translateXPos =
					child.css('transform') !== 'none'
						? parseFloat(
								child.css('transform').split(',')[4].trim()
						  )
						: 0,
				absLeft = childLeft - translateXPos,
				dir = 'rtl',
				animateWidth =
					childWidth >= wW
						? dir === 'ltr'
							? Math.abs(absLeft) // text cutoff from left and last character within the view and ltr
							: absLeft <= 0
							? childWidth - wW - Math.abs(absLeft) // text cutoff or align from left and last character beyond the view and rtl
							: Math.abs(absLeft) + (childWidth - wW) // text not cutoff from left and rtl
						: dir === 'ltr'
						? absLeft > 0
							? wW - (Math.abs(absLeft) + childWidth) // text within the view and ltr
							: Math.abs(absLeft) + (wW - childWidth) //text cutoff from left and rtl
						: Math.abs(absLeft), // text within the view or align right
				speed =
					dir === 'ltr'
						? (animateWidth / wH) * -1
						: animateWidth / wH;
			parent.attr({
				'data-speed': speed,
			});
		};
	$(() => {
		animatingEls.each((ind, ele) => {
			const thisEl = $(ele),
				thisParent = thisEl.parents('.bs-div--r-scltxt-l1-glb1');
			speedFunc(thisParent, thisEl);
			$(window).on('resize', () => {
				speedFunc(thisParent, thisEl);
			});
			$(window).on('scroll', () => {
				const boundingBox = animatingEls[ind].getBoundingClientRect(),
					wH = $(window).height(),
					elTop = boundingBox.top,
					elBottom = boundingBox.bottom;
				if (elTop < wH && elBottom > 0) {
					const scrollTop = wH - elTop,
						elSpeed = parseFloat(
							thisEl
								.parents('.bs-div--r-scltxt-l1-glb1')
								.attr('data-speed')
						),
						elClientSpeed = parseFloat(
							thisEl
								.parents('.bs-div--r-scltxt-l1-glb1')
								.attr('data-client-speed')
						),
						animatePixelVal =
							elClientSpeed > 0
								? scrollTop *
								  (elSpeed + (elSpeed * elClientSpeed) / 100)
								: scrollTop * elSpeed;
					thisEl.css({
						transform: 'translateX(' + animatePixelVal * -1 + 'px)',
					});
				}
			});
		});
	});
})(jQuery);
