/* eslint-env jquery */
/* global debounce */

$(($) => {
	
	const appendContentBox = (parentElement, childElement, clickedItem, popupElement) => {
		const parentEle = $(parentElement);
		const clickedElement = $(clickedItem);
		clickedElement.removeAttr('data-fancybox');
		parentEle.find('[data-src]').attr('target', '');
		if(parentEle.length) {
			$('.bs-column--infobox').remove();
			const parentWidth = parentEle.width();
			const childrenWidth = parentEle.children().width();
			const divGroup = parentEle.find(childElement);
			$(childElement).removeClass('last-child');
			let rowValue = 0;
			let itemsPerRow = 0;
			if(parentWidth / 3 > childrenWidth) {
				itemsPerRow = 3;
			} else if(parentWidth / 2 > childrenWidth) {
				itemsPerRow = 2;
			} else if(parentWidth > childrenWidth) {
				itemsPerRow = 1;
			}
			for(let i = 0; i < divGroup.length; i+=itemsPerRow) {
				divGroup.slice(i, i+itemsPerRow).attr('data-row', rowValue);
				$(`[data-row=${rowValue}]:last`).addClass('last-child');
				rowValue+=1;
			}
		}
		
		clickedElement.on('click', (e) => {
			$('.bs-column--infobox').remove();
			parentEle.find(childElement).removeClass('active-popup');
			$(e.currentTarget).parents(childElement).addClass('active-popup');
			parentEle.addClass('has-active-popup');
			$('#custom-popup, .facetwp-load-more').addClass('has-visible-popup');
			const rowValue = $(e.currentTarget).parents(childElement).attr('data-row');
			const popupContent = $(e.currentTarget).parents(childElement).find(popupElement).html();
			$(`<div class='bs-column col-md-12 bs-column--infobox' style='display: none;'><span class="close-popup"></span>${popupContent}</div>`).insertAfter(`.last-child[data-row='${rowValue}']`);
			$('.bs-column--infobox').slideDown(600, () => { 
				const scrollValue =
			$(e.currentTarget).parents(childElement).offset().top -	$('header').height();
			$('html, body').animate(
				{ scrollTop: scrollValue },
				500
			);
			});
		});

		if($('.active-popup').length) {
			const rowValue = $('.active-popup').attr('data-row');
			const popupContent = $('.active-popup').find(popupElement).html();
			parentEle.addClass('has-active-popup');
			$(`<div class='bs-column col-md-12 bs-column--infobox'><span class="close-popup"></span>${popupContent}</div>`).insertAfter(`.last-child[data-row='${rowValue}']`);
		}

		$(document).on('click', '.close-popup', (e) => {
			$('.bs-column--infobox').slideUp(600, () => { 
				$(this).remove(); 
				parentEle.find(childElement).removeClass('active-popup');
				parentEle.removeClass('has-active-popup');
				$('#custom-popup, .facetwp-load-more').removeClass('has-visible-popup');
			});
		});

	}

	appendContentBox('.expandable_content_wrapper', '.bs-posts__column', '.expandable_content_wrapper .bs-post__trigger[data-src]', '.bs-post__target--popup-post');

	$(document).on('facetwp-loaded', () => {
		if (FWP.loaded) {
			appendContentBox('.expandable_content_wrapper', '.bs-posts__column', '.expandable_content_wrapper .bs-post__trigger[data-src]', '.bs-post__target--popup-post');
		}
	});
	
	const debouncedHandlers = () => {
		appendContentBox('.expandable_content_wrapper', '.bs-posts__column', '.expandable_content_wrapper .bs-post__trigger[data-src]', '.bs-post__target--popup-post');
	};

	// Calls on window resize
	$(window).on('resize', debounce(debouncedHandlers, 100));

});
