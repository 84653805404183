/* global debounce */
$(($) => {
  const accordionBlock = () => {
    $('button.news-expandable').on('click',
      debounce((e) => {
        const scrollValue =
          $(e.currentTarget).closest('.card').offset().top - 160
        $('html, body').animate({ scrollTop: scrollValue }, 800);
      }, 500)
    );
    
		$('button.news-expandable').on('click', function (e) {
      const button = $(e.target).closest('button');
      const card = button.parent().parent();
      $('.bs-post-news .card').removeClass('card-active');
      if (button.attr('aria-expanded') !== 'false') {
        card.removeClass('card-active');
      } else {
        card.addClass('card-active');
      }
   
    });
	};
  accordionBlock();
	$(document).on('facetwp-loaded', () => {
		if (FWP.loaded) {
      accordionBlock();
		}
	});

});
