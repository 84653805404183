$(($) => {
	$('a[href="#onetrust-consent-sdk"]').on('click', (e) => {
		e.preventDefault();
		$(
			'#onetrust-consent-sdk>.onetrust-pc-dark-filter, #onetrust-consent-sdk>#onetrust-pc-sdk'
		)
			.removeClass('ot-hide')
			.attr('style', '');
	});
});
