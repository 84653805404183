$(($) => {
	const items = $('.bs-row--sass-talk-inner-cont .bs-column--sass-talk-line');
	const animatedClassName = 'line-active';
	const observer = new IntersectionObserver(
		(entries, observer) => {
			entries.forEach((entry) => {
				const item = $(entry.target);
				if (entry.isIntersecting) {
					item.addClass(animatedClassName); 
				} else { 
					item.removeClass(animatedClassName);
				}
			});
		}, 
	);
	items.each((i, item) => {
		observer.observe(item);
	});
});
