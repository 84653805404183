/* eslint-env jquery */
(($) => {
  const hasItem = $('.bs-div--parallax-item');
  if(hasItem.length !== 0) {
    $(window).on('scroll', () => {
      $('.bs-div--parallax-item').addClass('enable-parallax');
      $('.bs-div--parallax-item').parallaxMove({ offset: 200});
    });
    $(window).on('resize', () => {
      $('.bs-div--parallax-item').removeClass('enable-parallax');
      $('.bs-div--parallax-item').css('transform','translateY(0)')
    });
  }
})(jQuery);
